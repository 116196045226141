<template>
  <!---------------------------여기부터 종합 리포트--------------------------------------->

  <div class="report-container">
    <!-- Tab Navigation -->
    <div class="tab-nav">
      <button
        @click="selectedTab = 'summary'"
        :class="{ active: selectedTab === 'summary' }"
      >
        종합 리포트
      </button>
      <button
        @click="selectedTab = 'detailed'"
        :class="{ active: selectedTab === 'detailed' }"
      >
        세부 학습 리포트
      </button>
      <button
        @click="selectedTab = 'page'"
        :class="{ active: selectedTab === 'page' }"
      >
        페이지별 리포트
      </button>
    </div>

    <!-- Tab Content -->
    <div class="tab-content">
      <!-- 종합 리포트 -->
      <div v-show="selectedTab === 'summary'" class="summary-section">
        <div class="summary-header">
          <div class="profile-card">
            <div class="profile-info">
              <img
                class="profile-pic"
                alt="profile-pic"
                src="@/assets/profile_img.png"
              />
              <!--프로필 이미지-->
              <div class="profile-details">
                <p>{{ userData?.schoolText }}</p>
                <h2>{{ userData?.name }}</h2>
              </div>
            </div>
            <div class="score-icon-container">
              <img
                class="score-icon"
                alt="score icon"
                src="@/assets/score_icon.png"
              />

            <div class="score">
              <span>종합 성취도</span>
              <h3>{{ totalAchievement }}%</h3>
            </div>
            </div>
          </div>
        </div>

        <div class="summary-cards">
          <div class="result-card">
            <h2>종합 결과</h2>
            <p>
              <vue-markdown :source="totalFeedback" />
            </p>
          </div>

          <div class="graph-card">
            <canvas id="radarChart" class="graph-placeholder"></canvas>
          </div>
        </div>
      </div>

      <!-- 세부 학습 리포트 -->
      <div v-show="selectedTab === 'detailed'" class="detailed-section">
        <div class="detailed-card criteria-card">
          <div class="criteria-text">
            <div class="text-tag">영역</div><div class="criteria-header">{{ unitData.unit }}</div>
          </div>
          <div class="criteria-text">
          <div class="text-tag">성취기준</div> <div class="criteria-header">{{ unitData.criteria }}</div>
          </div>
        </div>

        <div
          class="detailed-card evaluation-card"
          v-for="item in detailLearningReport"
          :key="item.title"
        >
          <div class="evaluation-card-left">
          <h3>{{ item.title }}</h3>
          <div class="rating-box">자기평가: {{ item.selfEvaluation }}</div>
          </div>
          <vue-markdown :source="item.content" class="evaluation-card-right" />
        </div>
      </div>

      <!-- 페이지별 리포트 -->
      <div v-show="selectedTab === 'page'" class="page-section">
        <div class="page-list">
          <h2>페이지</h2>
          <ul class="page-list-section">
            <li
              v-bind:key="index"
              v-for="(page,index) in pageList"
              @click="selectPage(index + 1)"
              :class="{ selected: selectedPage === index + 1}"
            >

              #{{index+1}}. {{ page }}
            </li>

          </ul>
        </div>
        <div class="vertical-line"></div>
        <div class="evaluation-list">
          <h2>평가 요소</h2>
          <ul>
            <li
              v-for="(element, index) in evaluationElements[selectedPage - 1]"
              :key="index"
              class="evaluation-item"
            >
              <div class="evaluation-item-left">{{ element.text }}</div>
              <div class="evaluation-item-right">{{element.score}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import {
  getEvaluationLesson,
  getEvaluationRubric,
  getEvaluationSegment,
  getLessons,
  getRubrics, getStagesAll,
  getUser
} from "@/apis/get";
import Cookies from "js-cookie";
import VueMarkdown from 'vue-markdown-render'

export default {
  name: "EndPageComponent",
  props: {
    lessonId: {
      type: Number,
      required: true,
    },
  },
  components: {
    VueMarkdown
  },

  data() {
    return {
      chartData : {
        labels : [],
        data : []
      },
      userData: {
        schoolText: "",
        name: "",
      },

      unitData: {
        unit: "",
        criteria: "",
      },

      totalFeedback: "",
      totalAchievement: 80,

      selectedTab: "summary",
      selectedPage: 1,
      detailLearningReport: [],
      pageList:[],
      evaluationElements: [],
    };
  },
  methods: {
    async getLessonFeedBack () {
     const { data } = await getEvaluationLesson({
       sessionId:  Cookies.get('lesson_cookie')
     })

      this.totalAchievement = data.overall_score


      this.totalFeedback = data.feedback
    },
    selectPage(pageNumber) {
      this.selectedPage = pageNumber;
    },

    async getUnitData(lessonId) {
      const { data } = await getLessons();
      const lesson = data.find((lesson) => lesson.id === lessonId);
      this.unitData = {
        unit: lesson?.unit_area,
        criteria: lesson?.achievement_criteria,
      };
    },

    async getSegmentReport () {

      const { data : stages } =    await  getStagesAll(this.lessonId)

      this.pageList = stages.sort((a,b)=> a.position - b.position).map((stage) => stage.name).filter((stage) => stage !== "자기평가 및 느낀 점" && stage !== "결과페이지");
      const EVALUATION = [ '🟠', '🟡', '🟢' ]
      const EVALUATION_TEXT = ['노력이 필요해요', '더 잘할 수 있어요', '멋지게 해냈어요']

      Promise.all(
        stages.map(async (stage) => {
          const { data } = await getEvaluationSegment({
            sessionId: Cookies.get("lesson_cookie"),
            stageId: stage.id,
          });

          return data;
        })
      ).then(
        (data) => {
          this.evaluationElements = data.map((item) => item.map((element) => {
            return {
              text: element.feedback,
              score: EVALUATION[element.score - 1] + " " + EVALUATION_TEXT[element.score - 1]
            }
          }))
        }
      )
    },

    async getDetailLearningReport(lessonId) {
      const self_feed_back = ["strongly_disagree" ,"disagree","agree" ,"strongly_agree"]
      const { data } = await getRubrics(lessonId);
      const {data: rubricData } = await getEvaluationRubric({
        sessionId: Cookies.get('lesson_cookie')
      })

      this.chartData.labels = data.map((item) => item.content);
      this.chartData.data = rubricData.map((item) => item.total_score);

      this.detailLearningReport = data.map((item,index) => {
        const selfEvaluationCount = self_feed_back.findIndex((v) => v === rubricData[index].self_assessment) + 1

        return {
          title: item.content,
          content:rubricData[index].feedback,
          selfEvaluation: Array.from({   length: selfEvaluationCount },() => "😊").join("")
        };
      });
    },
    async setUserData() {
      const { data } = await getUser();
      this.userData = {
        schoolText: `${data.school} ${data.grade}학년 ${data.class_number}반 ${data.student_number}번`,
        name: data.name,
      };
    },
  },
  watch: {
    chartData : {
      handler() {
        const ctx = document.getElementById("radarChart");
        new Chart(ctx, {
          type: "radar",
          data: {
            labels: this.chartData.labels,
            datasets: [
              {
                data: this.chartData.data,
                backgroundColor: "rgba(255, 204, 0, 0.2)",
                borderColor: "rgba(255, 204, 0, 1)",
                borderWidth: 2,
                pointRadius: 0,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false, // 범례 숨김
              },
            },
            scales: {
              r: {
                angleLines: {
                  display: true,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                  display: false, // 축 레이블 제거
                  stepSize: 20,
                },
              },
            },
          },
        });
      },
      deep: true
    }

  },

  mounted() {
    Chart.register(...registerables);

    this.setUserData();
    this.getDetailLearningReport(this.lessonId);
    this.getUnitData(this.lessonId);
    this.getLessonFeedBack();
    this.getSegmentReport();

  },
};
</script>

<style scoped>
/* 타이틀바 */
.title-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  z-index: 2;
}

.top-container {
  display: flex;
  justify-content: flex-start; /*추가*/
  width: 100%;
  background: #5c7fb4;
}

.carrot {
  margin: 12px 0 10px 50px;
  width: 135px;
  height: 30px;
  cursor: pointer;
}

.title2 {
  flex-grow: 1;
  color: #000;
  font-family: "NanumSquare", sans-serif;
  font-size: 22px;
  text-align: left;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 10px;
}

.progress-container {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin: 15px 30px;
}

.progress-bar {
  position: absolute; /* 부모 요소를 기준으로 위치를 설정 */
  right: 50px; /* 부모의 왼쪽에서 10px 떨어진 곳에 배치 */
}

.text-container {
  display: inline-flex;
  height: auto;
  background: #3f5b86;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding: 2px 24px 4px;
  margin: 10px 0;
  margin-left: 30px;
}

.text-content {
  text-align: center;
  color: #e5d8a3;
  font-size: 15px;
  font-family: "Pretendard", sans-serif;
  font-weight: 600;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

/************************************************종합 리포트***************************************************/

.report-container {
  width: 85%;
  margin: 80px auto 40px;
  font-family: "Arial", sans-serif;
}

/* 탭 네비게이션 스타일 */
.tab-nav {
  display: flex;
  justify-content: flex-start;
  width: 45%;
  background-color: white;
  border-radius: 8px 8px 0 0;
}

.tab-nav button {
  flex: 1;
  padding: 15px 0;
  margin: 0 7px;
  border: none;
  background-color: #abd3f9;
  cursor: pointer;
  font-size: 20px;
  color: #111111;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: background-color 0.3s, color 0.3s;
}

.tab-nav button:hover {
  background-color: #cfe2ff;
}

.tab-nav button.active {
  background-color: #e5f3fd;
  color: #111111;
}

/* 탭 내용 스타일 */
.tab-content {
  height: 600px;
  padding: 20px;
  background-color: #e5f3fd;
  overflow-y: auto;
}

/* 종합 리포트 스타일 */
.summary-section {
  display: flex;
  flex-direction: column;
  gap: 20px; /* 카드 간 간격 */
}

/* 카드 스타일 */
.summary-header {
  display: flex;
  justify-content: flex-start; /* 프로필 카드가 왼쪽에 위치 */
  align-items: center;
}

/* 프로필 카드 스타일 */
.profile-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* 프로필 카드 폭 설정 */
  margin-bottom: 15px; /* 카드 간 간격 */
  margin-top: 15px;
}

.profile-info {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.profile-pic {
  margin-right: 50px;
}

.score {
  text-align: left;
  padding: 0 30px;

}

.score-icon-container {
  display: flex;
  align-items: center;
}

.score-icon {
  width: 30px; /* 이미지 크기 조정 */
  height: 30px; /* 이미지 크기 조정 */
  vertical-align: middle; /* 텍스트와 정렬 */
}

/* 카드 배치 */
.summary-cards {
  display: flex; /* 카드들을 나란히 배치 */
  justify-content: space-between; /* 양쪽으로 배치 */
  gap: 30px;
}

.result-card,
.graph-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  height: 330px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  line-height: 1.7;
}

.result-card {
  flex: 1; /* 폭을 늘리기 */
  width: 100%;
  overflow-y: auto;
}

.graph-card {
}

.graph-placeholder {
  width: 100%;
  height: 340px; /* 그래프 표시 영역 높이 */
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 세부 학습 리포트 스타일 */
.detailed-section {
  display: flex;
  flex-direction: column; /* 세로로 배치 */
  gap: 20px; /* 카드 간의 간격 */
  align-items: center; /* 카드를 가운데 정렬 */
}

.detailed-card {
  width: 95%; /* 카드가 전체 폭을 차지하도록 */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

/* 각 카드 내부 스타일 */
.criteria-card {
  text-align: center; /* 첫 번째 카드 텍스트 정렬 */
}
.evaluation-card {
  display: flex;
  flex-direction: row;
}
.evaluation-card-right {
  flex: 1
}

.evaluation-card-left {
  width: 215px;
  justify-content: space-around;
  display: flex;

  align-items: center;

  flex-direction: column;

  border-right: 4px solid #E4E9EE;

}
.evaluation-card h3 {
  text-align: center;
  word-break: keep-all;

  width: 170px;
}

.rating-box {
  margin-top: 10px;
}

/* 페이지별 리포트 스타일 */
.page-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  height: 550px;
}

.criteria-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.text-tag {
  border-radius: 30px;
  background-color: #B4B8BE;
  color: white;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.71px;
  text-align: center;
  width: 80px;
}

.page-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: -20px;
}

.page-list-section {
  flex: 1;
  overflow-y: auto;
}

.page-list ul {
  list-style: none;
  padding: 0;
}

.page-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 30px;
  margin-left: 10px;
  font-size: 19px;
}

.page-list li:hover {
  background-color: #eef;
}

.page-list li.selected {
  background-color: #c7e1f0;
  border-radius: 50px;
  padding: 20px;
}

.page-list h2 {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
  font-family: Arial, Helvetica, sans-serif;
}

.vertical-line {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.567);
  height: 100%;
  margin: 0 40px;
}

.evaluation-item {
  border-radius: 30px;
  margin: 5px 0;
  background-color: white;
  margin-bottom: 30px;
  font-size: 20px;
  padding: 15px 20px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  display: flex;

  gap: 20px;
}

.evaluation-item-left {
  flex: 1;
}

.evaluation-item-right {
  display: flex;
  align-items: center;
}

.evaluation-list {
  flex: 2;
  padding-left: 5px;
}

.evaluation-list ul {
  list-style: none;
  padding: 0;
}

.evaluation-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.evaluation-list h2 {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
